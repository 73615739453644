<template>
  <div class="brands">

    <NavHeader />
    <div class="content_container content">
      <div class="banner">

        <!-- <img class="banner04" src="../../assets/images/chain_banner.jpg" alt="" /> -->
        <el-carousel trigger="click" height="250px">
          <el-carousel-item v-for="item in bannerUrl" :key="item.id">
            <a :href=item.href target="_blank">
            <img class="banner04" :src=item.src alt="" />
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>

      <main>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
          label-position="left">

          <el-form-item label="供需选择" prop="tabPosition">
            <section class="chain-change" style="display: flex;margin-top: 4px;">
              <div class="change-need-btn change-btn" @click="ruleForm.tabPosition = 2"
                :class="ruleForm.tabPosition == 2 ? 'addcolor1' : ''">需求</div>
              <div class="change-apply-btn change-btn" @click="supplybtn(1)"
                :class="ruleForm.tabPosition == 1 ? 'addcolor2' : ''">供应</div>
            </section>
          </el-form-item>

          <el-form-item label="供需内容" prop="content">
            <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.content">
            </el-input>
          </el-form-item>

          <el-form-item label="截止时间" prop="end_time">
            <el-date-picker v-model="ruleForm.end_time" type="datetime" placeholder="选择日期时间" align="right"
              value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions">
            </el-date-picker>
            （可不填，默认永久有效）
          </el-form-item>

          <el-form-item label="联系方式" prop="phone" style="width: 600px;">
            <el-input v-model="ruleForm.phone" clearable></el-input>
          </el-form-item>

          <el-form-item label="附加说明" prop="orther_illustrate" style="width: 600px;">
            <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="ruleForm.orther_illustrate">
            </el-input>
          </el-form-item>

          <el-form-item style="text-align: center;">
            <el-button type="primary" @click="login">发布</el-button>
          </el-form-item>

        </el-form>
      </main>


    </div>

  </div>
</template>
<script>
import NavHeader from "@/components/layout/nav-header";

export default {
  data() {
    return {
      banner: [],
      pickerOptions: {
        shortcuts: [{
          text: '三天后',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 3);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周后',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }],
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000;
        }
      },


      ruleForm: {
        content: '',
        end_time: '',
        phone: '',
        orther_illustrate: '',
        tabPosition: 2,
      },
      rules: {
        content: [
          { required: true, message: '请输入供需内容', trigger: 'blur' },
        ],
      },
      // banner图轮播
      bannerUrl:[
      {id:2,src:require('../../assets/images/chain_banner2.jpg'),href:'http://test.861718.com/2024/#/home'},
      {id:3,src:require('../../assets/images/chain_banner3.jpg'),href:'http://new.861718.com/2024/#/home'},
      {id:1,src:require('../../assets/images/chain_banner1.jpg'),href:'#'},
      ],
      
    };
  },
  computed: {
    member() {
      return this.$store.state.member;
    },
    shop() {
      return this.$store.state.shop;
    }
  },
  mounted() {
    this.getBanner();


  },
  methods: {
    //供应按钮
    supplybtn(id) {
      this.ruleForm.tabPosition = id
      if (!this.member.shopId) {
        this.$message("需要入驻的店铺才能发布供应信息");
        return;
      }
    },
    getBanner() {
      this.$get("home/ad", { pid: 541 }).then(res => {
        this.banner = res || [];
      });
    },


    login() {
      if (!this.member.id) {
        this.$alert("请先登录", {
          confirmButtonText: "确定"
        }).then(() => {
          this.$router.push({
            path: "/login"
          });
        });
        return;
      }
      if (this.ruleForm.tabPosition == 1) {
        if (!this.member.shopId) {
          this.$message("需要入驻的店铺才能发布供应信息");
          return;
        }
      }
      this.$refs["ruleForm"].validate((valid) => {
        var end_time1 = this.ruleForm.end_time || "2036-8-14 20:26:40"

        if (valid) {
          this.$post("/seller/supplyDemand", {
            shop_id: this.member.shopId,
            user_id: this.member.id,
            type: this.ruleForm.tabPosition,
            content: this.ruleForm.content,
            end_time: end_time1,
            orther_illustrate: this.ruleForm.orther_illustrate,
          }).then(res => {
            console.log(res, 266)
            this.$message({
              message: '发布成功',
              type: 'success'
            })
            this.$router.push('/chain')
          });

        }
      })
    },

  },
  components: {
    NavHeader
  },
};
</script>
<style lang="scss" scoped>
.addcolor1 {
  background: #0054FF;
  color: #fff !important;
}

.addcolor2 {
  background: #E60012;
  color: #fff !important;
}

.chain-change {
  .change-btn {
    cursor: pointer;
    width: 76px;
    height: 28px;
    text-align: center;
    line-height: 26px;
    font-size: 16px;
    transition: all 0.2s;
  }

  .change-need-btn {
    border: 1px solid #0054FF;
    color: #0054FF;
    border-right: 0;
  }

  .change-apply-btn {
    border: 1px solid #E60012;
    border-left: 0;
    color: #E60012;
  }
}

::v-deep .el-button--primary {
  margin-top: 10px;
  width: 260px;
  background: #0054FF;
  border: 0;

  span {
    font-size: 16px;
  }

}

::v-deep .el-button--primary:hover {
  background: #1a64fb;
}

::v-deep .el-textarea__inner:focus {
  border-color: #0054FF;
}

::v-deep .el-input__inner:focus {
  border-color: #0054FF;
}

.banner {
  width: 100%;
  // height: 350px;

  img {
    width: 100%;
    height: 100%;
  }
}

.demo-ruleForm {
  width: 1060px;
  margin-top: 50px;
}
</style>
 